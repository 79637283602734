.container {
    /*height: 100vh;*/
    padding: 16px;
    border-radius: 8px;
    background: #ffffff;
}

.title {
    font-size: 24px;
}

.wrap {
    width: 400px;
    margin: 159px auto 0;
    text-align: center;
}

.error_icon {
    width: 80px;
    height: 80px;
}

.caption {
    margin: 24px 0 16px;
    font-weight: 700;
    font-size: 28px;
}

.info {
    margin-bottom: 24px;
    font-size: 17px;
    color: rgba(0, 0, 0, 0.72);
}

.nav_link {
    margin-left: 20px;
    background: rgba(81, 122, 138, 0.08);
    color: rgba(0, 0, 0, 0.96);
}
