.paginationContainer {
    display: flex;
    padding-bottom: 10px;
}

.page,
.active_page {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    margin-right: 7px;
    border-radius: 4px;
    cursor: pointer;
    text-decoration: none;
    font-size: 13px;
    letter-spacing: 0.5px;
}

.page {
    background: rgba(81, 122, 138, 0.08);
}

.active_page {
    background: rgba(44, 109, 255, 1);
    color: #ffffff;
}
