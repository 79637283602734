.title {
    margin-bottom: 10px;
    font-size: 24px;
    font-weight: 400;
    letter-spacing: 0.1px;
}

.container {
    /*height: 100vh;*/
    padding: 16px;
    border-radius: 8px;
    background: #ffffff;
}

.doc_wrap {
    width: 60%;
    margin-bottom: 40px;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 6px;
    font-size: 15px;
}

.doc {
    display: block;
    padding: 12px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    color: rgba(0, 0, 0, 0.96);
}

.doc:last-child {
    border: none;
}

.doc:hover {
    background: rgba(81, 122, 138, 0.08);
}

.buttonContainer {
    display: flex;
    background: #f0f3f4;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 6px;
    width: 460px;
    height: 40px;
    font-size: 13px;
    letter-spacing: 0.5px;
}
.active {
    background: #ffffff;
    margin: 3px;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.0392157), 0px 2px 8px rgba(0, 0, 0, 0.0784314), 0px 4px 8px rgba(0, 0, 0, 0.0196078),
        0px 12px 16px rgba(0, 0, 0, 0.0196078), 0px 16px 32px rgba(0, 0, 0, 0.0196078), 0px 32px 32px rgba(0, 0, 0, 0.0196078);
}
.item {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 5px;
    width: 226px;
    border-radius: 4px;
    cursor: pointer;
}
.navContainer {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}
.nav {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: rgba(0, 0, 0, 0.96);
}
