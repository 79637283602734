.title {
    margin-bottom: 40px;
    font-size: 24px;
}

.container {
    /*height: 100vh;*/
    padding: 16px;
    border-radius: 8px;
    background: #ffffff;
}

.doc_wrap {
    width: 60%;
    margin-bottom: 40px;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 6px;
    font-size: 15px;
}

.doc {
    display: block;
    padding: 12px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    color: rgba(0, 0, 0, 0.96);
}

.doc:last-child {
    border: none;
}

.doc:hover {
    background: rgba(81, 122, 138, 0.08);
}

.dateInput {
    width: 250px;
    height: 40px;
    display: flex;
    align-items: center;
    padding: 0px 12px;
    background: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 6px;
    font-size: 15px;
    margin: 15px 0px 15px 0px;
}

.buttonContainer {
    margin-top: 20px;
    margin-bottom: 20px;
    display: flex;
}

.tab,
.active_tab {
    margin-right: 22px;
    font-weight: 700;
    font-size: 15px;
    letter-spacing: 0.4px;
    color: rgba(0, 0, 0, 0.54);
    cursor: pointer;
}

.active_tab {
    padding-bottom: 14px;
    border-bottom: 2px solid rgba(44, 109, 255, 1);
    color: rgba(0, 0, 0, 0.96);
}

.downloadButtonExtraClass {
    margin-left: 0;
}

.picker_wrap {
    margin-bottom: 0px;
    display: flex;
}

.okpo,
.iban {
    margin-left: 10px;
}

.okpo input {
    width: 120px;
    height: 40px;
    display: flex;
    align-items: center;
    padding: 0px 12px;
    background: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 6px;
    font-size: 15px;
    margin: 15px 0px 15px 0px;
    outline: none;
}

.iban input {
    width: 270px;
    height: 40px;
    display: flex;
    align-items: center;
    padding: 0px 12px;
    background: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 6px;
    font-size: 15px;
    margin: 15px 0px 15px 0px;
    outline: none;
}

.filterSendButton {
    margin-bottom: 10px;
}

.filterContainer {
    display: flex;
    align-items: center;
    position: relative;
}

.clear {
    cursor: pointer;
    background-color: #216ba5;
    color: #fff;
    border-radius: 50%;
    height: 16px;
    width: 16px;
    padding: 2px;
    font-size: 12px;
    text-align: center;
    display: table-cell;
    vertical-align: middle;
    position: absolute;
    right: 6px;
}
