.container {
    padding: 16px;
    border-radius: 8px;
    background: #ffffff;
}

.btn {
    margin-bottom: 24px;
}

.btn_sign {
    margin-top: 24px;
}
.qrLoader {
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    position: fixed;
}
