.title {
    margin-bottom: 40px;
    font-size: 24px;
}

.container {
    /*height: 100vh;*/
    padding: 16px;
    border-radius: 8px;
    background: #ffffff;
}

.doc_wrap {
    margin-bottom: 40px;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 6px;
    font-size: 13px;
}

.doc_item {
    display: flex;
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    padding: 2px 1px 2px 0px;
    justify-content: space-between;
}

.title_item {
    display: flex;
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    justify-content: space-between;
}

.title_item {
    background: rgba(81, 122, 138, 0.08);
}

.doc_title,
.doc {
    padding: 12px;
}

.doc {
    width: 100%;
    color: rgba(0, 0, 0, 0.96);
}

.doc_item:last-child {
    border: none;
}

.doc_item:hover {
    background: rgba(81, 122, 138, 0.08);
}

.dateInput {
    width: 250px;
    height: 40px;
    display: flex;
    align-items: center;
    padding: 0 12px;
    background: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 6px;
    font-family: sans-serif;
    font-size: 15px;
    cursor: pointer;
    /*margin: 15px 0px 15px 0px;*/
}

.dateInput:before {
    content: url('../../theme/assets/icons/picker_icon.svg');
    display: block;
    width: 24px;
    height: 24px;
    margin-right: 10px;
}

.line {
    width: 100%;
    height: 1px;
    margin: 20px 0;
    background: rgba(0, 0, 0, 0.15);
}

.picker_wrap {
    margin-bottom: 20px;
}

.qrLoader {
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    position: fixed;
}

.buttonContainer {
    margin-top: 20px;
    margin-bottom: 20px;
    display: flex;
}

.tab,
.active_tab {
    margin-right: 22px;
    font-weight: 700;
    font-size: 15px;
    letter-spacing: 0.4px;
    color: rgba(0, 0, 0, 0.54);
    cursor: pointer;
}

.active_tab {
    padding-bottom: 14px;
    border-bottom: 2px solid rgba(44, 109, 255, 1);
    color: rgba(0, 0, 0, 0.96);
}

.data {
    width: 140px;
    text-align: center;
}

.signButton {
    margin-right: 10px;
    padding: 0 10px;
}

.removeButton {
    margin-right: 10px;
    background: #e62222e3;
    padding: 0 10px;
}

.dataContainer {
    display: flex;
    align-items: center;
    position: relative;
}

.doc_title_container {
    display: flex;
}

.errorMessage {
    position: fixed;
    padding: 0 15px 0 15px;
    background: #f64a53;
    color: #ffffff;
    top: 15px;
    left: 50%;
    transform: translateX(-50%);
    border: 1px solid;
    height: 40px;
    border-radius: 6px;
    min-width: 360px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1), 0px 0px 8px rgba(0, 0, 0, 0.12), 0px 0px 8px rgba(0, 0, 0, 0.02), 0px 6px 16px rgba(0, 0, 0, 0.02),
        0px 12px 32px rgba(0, 0, 0, 0.02), 0px 16px 32px rgba(0, 0, 0, 0.03);
}

.fadeIn {
    animation: fadeInAnimation 0.5s ease-out forwards;
}

.fadeOut {
    animation: fadeOutAnimation 0.5s ease-in forwards;
}

.signButtonForbid {
    opacity: 0.6;
    background: #a39e9e;
}

/*.tooltip{*/
/*    position: absolute;*/
/*    bottom: 100%;*/
/*    left: 50%;*/
/*    transform: translateX(-50%);*/
/*    background-color: #333;*/
/*    color: #fff;*/
/*    padding: 5px 10px;*/
/*    border-radius: 5px;*/
/*    font-size: 12px;*/
/*    white-space: nowrap;*/
/*    z-index: 10;*/
/*    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);*/
/*}*/

@keyframes fadeInAnimation {
    from {
        top: -100px;
    }

    to {
        top: 15px;
    }
}

@keyframes fadeOutAnimation {
    from {
        top: 15px;
    }

    to {
        top: -100px;
    }
}
