.btn {
    display: inline-block;
    height: 40px;
    padding: 0 32px;
    background: #2c6dff;
    border-radius: 6px;
    align-items: center;
    font-size: 15px;
    line-height: 40px;
    letter-spacing: 0.4px;
    color: #ffffff;
    cursor: pointer;
    border: none;
    outline: none;
}
.tooltip {
    position: absolute;
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
    background-color: #333;
    color: #fff;
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 12px;
    white-space: nowrap;
    z-index: 10;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}
