.hide {
    display: none;
}

.fileError {
    display: flex;
    justify-content: center;
}

.loader {
    display: flex;
    justify-content: center;
    font-size: 30px;
}

.title {
    font-size: 24px;
    font-weight: 300;
}

.uploadFile {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 17px;
    gap: 8px;
    width: 357px;
    height: 40px;
    background: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 6px;
    margin-top: 8px;
}

.uploadTitle {
    height: 20px;
    font-size: 15px;
    line-height: 20px;
    display: flex;
    align-items: center;
}

.sendBtn {
    width: 230px;
    height: 40px;
    background: #2c6dff;
    border-radius: 6px;
    align-items: center;
    font-size: 15px;
    line-height: 20px;
    letter-spacing: 0.4px;
    color: #ffffff;
    cursor: pointer;
    margin-top: 24px;
    border: none;
    outline: none;
    padding: 0;
}

.fileFormat {
    font-size: 11px;
    line-height: 14px;
    letter-spacing: 0.6px;
    color: rgba(0, 0, 0, 0.72);
    margin-top: 8px;
}

.doc {
    font-weight: 700;
    font-size: 13px;
    line-height: 16px;
    letter-spacing: 0.6px;
    margin-top: 26px;
}

.arrow {
    width: 14px;
    height: 16.59px;
    background-image: url('../../theme/assets/icons/arrow.png');
    background-repeat: no-repeat;
    background-size: contain;
    margin-right: 13px;
}

.fileErrorText {
    color: red;
}
