.popUp {
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 5px;
    top: 50%;
    left: 50%;
    background: white;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    width: 800px;
    /*height: 450px;*/
    position: fixed;
    display: flex;
    flex-direction: column;
    animation-name: popUpAnimation;
    animation-duration: 0.5s;
    animation-iteration-count: inherit;
    padding: 5px 5px 20px 5px;
}
@keyframes popUpAnimation {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
.close {
    cursor: pointer;
    margin-right: 5px;
    margin-top: 5px;
    position: absolute;
}
.closeContainer {
    display: flex;
    justify-content: end;
}
.qrContainer {
    height: 100%;
    display: flex;
    /*justify-content: center;*/
    /*align-items: center;*/
    flex-direction: column;
    padding: 0px 15px 0px 15px;
}
.text {
    text-align: center;
    padding-top: 25px;
}
.popUpContainer {
    background: rgba(0, 0, 0, 0.4);
    height: 100%;
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
}
.destination {
    display: flex;
    justify-content: space-between;
    font-size: 12px;
    margin-top: 20px;
}
.destinationText {
    width: 70%;
}
.mainContainer {
    display: flex;
    margin-top: 25px;
    justify-content: space-between;
}
.iban {
    display: flex;
    font-size: 12px;
    margin-top: 12px;
    justify-content: space-between;
    margin-bottom: 6px;
}
.okpo {
    display: flex;
    font-size: 12px;
    justify-content: space-between;
    margin-bottom: 6px;
}
.who {
    display: flex;
    font-size: 12px;
    justify-content: space-between;
}
.sum {
    font-size: 27px;
    font-weight: bold;
    letter-spacing: 3px;
    margin-top: 5px;
    margin-right: 260px;
}
.sum span {
    color: rgb(138, 137, 137);
}
.sumContainer {
    padding-top: 25px;
    display: flex;
    align-items: baseline;
    margin-bottom: 10px;
    justify-content: space-between;
}
.title {
    font-size: 18px;
    font-weight: bold;
    color: dimgrey;
}
.titleData {
    font-size: 12px;
    margin-left: 10px;
}
.titleNumber {
    font-size: 18px;
}
.orderDetails {
    font-size: 12px;
    color: dimgrey;
    display: flex;
    cursor: pointer;
}
.circle {
    background: #808080bd;
    height: 10px;
    width: 10px;
    border-radius: 55%;
    margin-right: 5px;
    margin-top: 2px;
}
.leftContainer {
    width: 48%;
}
.rightContainer {
    width: 48%;
}
.bold {
    font-weight: bold;
}
.containerBorder {
    border-top: 1px solid #8080806b;
    margin-top: 10px;
}
.d,
.e {
    width: 125px;
}
.checkMark {
    font-size: 11px;
    cursor: pointer;
    margin-left: 4px;
    color: dimgrey;
}
.orderDetailsContainer {
    display: flex;
}
.result {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
}
.data {
    margin-left: 5px;
    margin-right: 15px;
}
.sumContainer .sum {
    /*margin-left: 139px;*/
}
.errorContainer {
    display: flex;
    margin-top: 10px;
}
.factsum {
    display: flex;
}
.rotate {
    transform: rotate(180deg);
}
.detailsFullList {
    display: flex;
    flex-direction: column;
    cursor: pointer;
}
.containerLogs {
    height: auto;
    max-height: 15px;
    transition: max-height 170ms ease-in-out;
    overflow: hidden;
}
.animation {
    max-height: 300px;
}
.downloadPdfButton {
    width: 40px;
    margin-right: 40px;
    position: absolute;
    cursor: pointer;
}
