.container {
    /*height: 100vh;*/
    padding: 16px;
    border-radius: 8px;
    background: #ffffff;
}

.title_wrap {
    display: flex;
    justify-content: space-between;
    margin-bottom: 40px;
}

.title {
    font-size: 24px;
}

.table {
    width: 100%;
    margin-bottom: 26px;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 6px;
    font-size: 13px;
}

.table_item {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}

.table_item:first-child {
    background: rgba(81, 122, 138, 0.08);
}

.table_item:last-child {
    border-bottom: none;
}

.table_item > span {
    padding: 7px;
    box-sizing: border-box;
}

.checkbox {
    width: 32px;
}

.input_checkbox {
    position: absolute;
    opacity: 0;
    height: 0;
    width: 0;
}

.label_checkbox {
    position: relative;
    display: inline-block;
    width: 16px;
    height: 16px;
    border: 1px solid rgba(30, 37, 39, 0.4);
    border-radius: 4px;
    cursor: pointer;
}

.input_checkbox:checked ~ .label_checkbox {
    border-color: rgba(44, 109, 255, 1);
    background: rgba(44, 109, 255, 1);
}

.label_checkbox:after {
    content: '';
    position: absolute;
    display: none;
    top: 3px;
    left: 3px;
    width: 8px;
    height: 4px;
    border-left: 2px solid #ffffff;
    border-bottom: 2px solid #ffffff;
    transform: rotate(-45deg);
}

.input_checkbox:checked ~ .label_checkbox:after {
    display: block;
}

.purpose {
    /*width: 12%;*/
    width: 189px;
}

.executorOkpo {
    /*width: 9%;*/
    width: 90px;
}

.result,
.result_null {
    /*width: 24%;*/
    width: 170px;
}

.result_null {
    text-align: center;
}

.debtorAccount {
    /*width: 27%;*/
    width: 250px;
}

.sum {
    /*width: 10%;*/
    width: 95px;
}

.docNum {
    /*width: 8%;*/
    width: 85px;
}

.state {
    /*width: 12%;*/
    box-sizing: content-box !important;
    width: 137px;
}

.action {
    position: relative;
    width: 30px;
    padding: 9px 0 !important;
}

.action_icon {
    cursor: pointer;
}

.dropdown_open,
.dropdown_hide {
    display: block;
    position: absolute;
    top: 32px;
    left: -51px;
    cursor: pointer;
}

.dropdown_hide {
    height: 0;
    padding: 0 !important;
    overflow: hidden;
}

.dropdown_open {
    /*display: flex;*/
    /*flex-direction: column;*/
    height: fit-content;
    padding: 5px;
    border-radius: 4px;
    background: #ffffff;
    box-shadow: 1px 0 3px rgba(40, 40, 40, 0.4);
    z-index: 22;
}

.status {
    position: relative;
    display: block;
    padding: 4px 12px 4px 28px !important;
    border-radius: 16px;
}

.status:before {
    content: '';
    display: block;
    position: absolute;
    top: 5px;
    left: 6px;
    width: 12px;
    height: 12px;
    border-radius: 50%;
}

.status_inwork {
    background: #f9e9c8;
}

.status_inwork:before {
    background: #fe8c1e;
}

.status_notSign {
    background: #e1e1e1;
}

.status_notSign:before {
    background: #9d9d9d;
}

.status_blocked {
    background: #fcd2cf;
}

.status_blocked:before {
    background: #fa242f;
}

.status_success {
    background: #e4f9c8;
}

.status_success:before {
    background: #75af26;
}
.signString {
    top: 58px;
    width: 70px;
}
.paginationContainer {
    display: flex;
    padding-bottom: 10px;
}

.paginationContainer div {
    padding-right: 5px;
    cursor: pointer;
    text-decoration: underline;
    font-size: 18px;
}

.tableGrid {
    display: grid;
    border-collapse: collapse;
    min-width: 100%;
    grid-template-columns:
        minmax(125px, 1.6fr)
        minmax(80px, 0.6fr)
        minmax(90px, 1.5fr)
        minmax(90px, 1fr)
        minmax(100px, 1.5fr)
        minmax(85px, 2fr)
        minmax(100px, 1fr)
        minmax(130px, 1fr)
        minmax(15px, 0.5fr);
}

.tableGrid thead,
.tableGrid tbody,
.tableGrid tr {
    display: contents;
    text-align: left;
    background: rgba(81, 122, 138, 0.08);
}

.tableGrid th,
.tableGrid td {
    padding: 10px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}
.tableGrid thead th {
    background: rgba(81, 122, 138, 0.08);
}
