@keyframes spinner {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.spinner {
    display: inline-block;
    width: 40px;
    height: 40px;
    border: 3px solid #0d9bd0;
    border-top: 3px solid #ffffff;
    border-radius: 100%;
    animation: spinner 1s linear infinite;
}

.spinner_wrap {
    text-align: center;
}
