.status {
    position: relative;
    display: block;
    padding: 4px 12px 4px 28px !important;
    border-radius: 16px;
}
.status:before {
    content: '';
    display: block;
    position: absolute;
    top: 5px;
    left: 6px;
    width: 12px;
    height: 12px;
    border-radius: 50%;
}

.status_inwork {
    background: #f9e9c8;
}

.status_inwork:before {
    background: #fe8c1e;
}

.status_notSign {
    background: #e1e1e1;
}

.status_notSign:before {
    background: #9d9d9d;
}

.status_blocked {
    background: #fcd2cf;
}

.status_blocked:before {
    background: #fa242f;
}

.status_success {
    background: #e4f9c8;
}

.status_success:before {
    background: #75af26;
}

.dropdown_open,
.dropdown_hide {
    display: block;
    position: absolute;
    top: 32px;
    left: -51px;
    cursor: pointer;
}

.dropdown_hide {
    height: 0;
    padding: 0 !important;
    overflow: hidden;
}

.dropdown_open {
    /*display: flex;*/
    /*flex-direction: column;*/
    height: fit-content;
    padding: 5px;
    border-radius: 4px;
    background: #ffffff;
    box-shadow: 1px 0 3px rgba(40, 40, 40, 0.4);
    z-index: 22;
}
.table_item {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}

.table_item:first-child {
    background: rgba(81, 122, 138, 0.08);
}

.table_item:last-child {
    border-bottom: none;
}

.table_item > span {
    padding: 9px;
    box-sizing: border-box;
}
.docNum {
    /*width: 8%;*/
    width: 90px;
}
.signString {
    top: 58px;
    width: 70px;
}
.action_icon {
    cursor: pointer;
}
.action {
    position: relative;
    width: 30px;
    padding: 9px 0 !important;
}
.state {
    /*width: 12%;*/
    box-sizing: content-box !important;
    width: 137px;
}
.result,
.result_null {
    /*width: 24%;*/
    width: 200px;
}

.result_null {
    text-align: center;
}
.sum {
    /*width: 10%;*/
    width: 95px;
}
.debtorAccount {
    /*width: 27%;*/
    width: 241px;
}
.purpose {
    /*width: 12%;*/
    width: 109px;
}
.executorOkpo {
    /*width: 9%;*/
    width: 90px;
}
