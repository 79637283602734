.popUp {
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 5px;
    top: 50%;
    left: 50%;
    background: white;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    width: 350px;
    height: 350px;
    position: fixed;
    display: flex;
    flex-direction: column;
    animation-name: popUpAnimation;
    animation-duration: 1.5s;
    animation-iteration-count: inherit;
    z-index: 999;
}
@keyframes popUpAnimation {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
.close {
    cursor: pointer;
    margin-right: 5px;
    margin-top: 5px;
    position: absolute;
}
.closeContainer {
    display: flex;
    justify-content: end;
}
.qrContainer {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.text {
    text-align: center;
    padding-top: 25px;
}
