.tableContainer {
}

.table {
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 6px;
    font-size: 13px;
}

.table_item {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}

.table_item:first-child {
    background: rgba(81, 122, 138, 0.08);
}

.table_item:last-child {
    border-bottom: none;
}

.table_item span {
    padding: 12px;
}

.purpose {
    width: 12%;
    display: flex;
    justify-content: space-between;
}

.executorOkpo {
    width: 10%;
}

.debtorName {
    width: 28%;
}

.debtorAccount {
    width: 30%;
}

.sum {
    width: 12%;
}

.docNum {
    width: 8%;
}
.pencil {
    width: 15px;
    height: 15px;
    cursor: pointer;
}
.popUp {
    background: white;
    height: 35%;
    width: 35%;
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border-radius: 5px;
    border: 1px solid;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.editText {
    width: 85%;
    height: 65%;
    border: none;
    resize: none;
    outline: none;
    overflow: auto;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    font-family: PryvatSans, sans-serif;
    color: rgba(0, 0, 0, 0.96);
}
.popUpButtonContainer {
    display: flex;
    width: 100%;
    justify-content: space-around;
    margin-top: 15px;
}
.cancelBtn {
    background: #f44336 !important;
}
.counter {
    font-size: 10px;
    color: #00000075;
    width: 85%;
}
