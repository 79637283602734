@font-face {
    font-family: 'PryvatSans';
    src: url('./theme/assets/fonts/PryvatSans-Regular.woff');
    font-weight: normal;
    font-style: normal;
}

body {
    margin: 0;
    font-family: PryvatSans, sans-serif;
    color: rgba(0, 0, 0, 0.96);
}

a {
    text-decoration: none;
}
